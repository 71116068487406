export const environment = {
    production: true,
    hmr       : false,
    firebase: {
        apiKey: "AIzaSyDXUfnjs3NpbwEMj2G7_1aHrXdCb6yi2Eg",
        authDomain: "blastitall-live.firebaseapp.com",
        databaseURL: "https://blastitall-live.firebaseio.com",
        projectId: "blastitall-live",
        storageBucket: "blastitall-live.appspot.com",
        messagingSenderId: "202589242224"
    },
};

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  { path: 'login/confirmed-list', redirectTo: 'confirmed-list', pathMatch: 'full' },
  { path: 'login/home', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login/confirmation-list', redirectTo: 'confirmation-list', pathMatch: 'full' },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'confirmation-list',
    loadChildren: './confirmation-list/confirmation-list.module#ConfirmationPageModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'confirmed-list',
    loadChildren: './confirmed-list/confirmed-list.module#ConfirmedPageModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'job-detail/:jobId',
    loadChildren: './pages/job-detail/job-detail.module#JobDetailPageModule',
    canActivate: [AuthGuard],
  },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  {
    path: 'reset-password',
    loadChildren: './pages/reset-password/reset-password.module#ResetPasswordPageModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
